(function() {
  "use strict";

  /*
  	！！注意！！
  	圧縮ファイルが本番用です。
  	どうしてもこのjsファイルを編集したい場合は
  	こちらを編集後圧縮してください。
  
  	基本的にはこのJSファイルを編集するのではなく
  	新しくJSファイルを作成してください。
   */

  /*
  
  	@global_value
   */
  var ACD_CNT, GET_RANDOMNUM, GET_SEASON, IMG_TICKER, IMG_TICKER_INIT, SAYANOYUDOKORO_MAIN, SEASON_EFFECT, SETTINGS, TAB_CNT, changeTextSize, getWindouSize, initmain, modeChange, moveMousePotion, obj_settings, resizeCommon, scrollEvt, scroll_flag, scroll_y, settingPC, settingSP, settingTAB, settings_main, startSteing, trace;

  $doc.bp_pc_com = 1247;

  $doc.bp_tab_com = 750;

  $doc.bp_tab_s_com = 1007;

  $doc.pc_flag = false;

  $doc.tab_flag = false;

  $doc.tab_s_flag = false;

  $doc.sp_flag = false;

  $doc.obj_acd = {};

  $doc.obj_tab = {};

  $doc.obj_tick = {};

  $doc.obj_season = {};

  $doc.obj_radm = {};

  $doc.obj_ssf = {};


  /*
  
  	@local_value
   */

  obj_settings = {};

  scroll_y = 0;

  scroll_flag = false;


  /*
  
  	基本オブジェクト
   */

  SETTINGS = {
    debug: {
      DUBUG_MODE: false,
      trace: function() {
        var e, i, k, len, ref, str;
        if (!this.DUBUG_MODE) {
          return;
        }
        try {
          if (arguments.length > 1) {
            str = '';
            len = arguments.length;
            for (i = k = 0, ref = len; 0 <= ref ? k <= ref : k >= ref; i = 0 <= ref ? ++k : --k) {
              if (i > 0) {
                str += ',';
                str += arguments[i];
              }
            }
            if (console) {
              console.log(str);
              this;
            }
          } else {
            if (console) {
              console.log(arguments[0]);
              this;
            }
          }
        } catch (error) {
          e = error;
          if (console) {
            console.log("Can't Output Console Log.");
          }
        }
        return this;
      }
    },
    hoverfade: {
      _alpha: 0,
      _delay: 0,
      init: function(_alpha, _delay, _classname) {
        var _parent, _target, classname;
        if (_classname) {
          classname = _classname;
        } else {
          classname = '.hover';
        }
        _parent = this;
        _parent._alpha = _alpha;
        _parent._delay = _delay;
        _target = $(classname);
        _target.mouseover(function(event) {
          _parent.onOverHandler($(this));
          return this;
        });
        return this;
      },
      onOverHandler: function(_target) {
        var _parent;
        _parent = this;
        $(_target).mouseout(function(event) {
          _parent.onOutHandler($(this));
          return this;
        });
        $(_target).stop(true, false).animate({
          opacity: _parent._alpha
        }, _parent._delay);
        return this;
      },
      onOutHandler: function(_target) {
        var _parent;
        $(_target).unbind("mouseout");
        _parent = this;
        $(_target).stop(true, false).animate({
          opacity: 1
        }, _parent._delay);
        return this;
      }
    }
  };


  /*
  
  	アコーディオン
   */

  ACD_CNT = {
    bt_acd: [],
    con_acd: [],
    init: function() {
      var _h, _len, _num, _parent, i, k, ref;
      _parent = this;
      _num = 0;
      $('.con_acd').each(function() {
        $(this).find('dl').each(function() {
          $(this).find('dt > a').attr('name', String(_num));
          _parent.bt_acd.push($(this).find('dt > a'));
          _parent.con_acd.push($(this).find('dd'));
          _num++;
          return this;
        });
        return this;
      });
      _len = _parent.con_acd.length;
      for (i = k = 0, ref = _len; 0 <= ref ? k <= ref : k >= ref; i = 0 <= ref ? ++k : --k) {
        $(_parent.bt_acd[i]).unbind('click');
        $(_parent.bt_acd[i]).click(function(event) {
          _parent.onClickHandler($(this));
          return this;
        });
        if ($(_parent.bt_acd[i]).hasClass('active')) {
          _h = $(_parent.con_acd[i]).find('.box_acd').innerHeight();
          $(_parent.con_acd[i]).css('height', String(_h) + 'px');
        }
      }
      return this;
    },
    onClickHandler: function(_target) {
      var _h, _parent, _value;
      _parent = this;
      _value = parseInt($(_target).attr('name'));
      _h = $(_parent.con_acd[_value]).find('.box_acd').innerHeight();
      if (!$(_parent.bt_acd[_value]).hasClass('active')) {
        $(_parent.bt_acd[_value]).addClass('active');
        $(_parent.con_acd[_value]).css('height', String(_h) + 'px');
        $(_parent.con_acd[_value]).addClass('active');
      } else {
        $(_parent.con_acd[_value]).css('height', '0');
        $(_parent.con_acd[_value]).removeClass('active');
        $(_parent.bt_acd[_value]).removeClass('active');
      }
      return this;
    },
    onShow: function(_value) {
      var _h, _parent;
      _parent = this;
      _h = $(_parent.con_acd[_value]).find('.box_acd').innerHeight();
      if (!$(_parent.bt_acd[_value]).hasClass('active')) {
        $(_parent.bt_acd[_value]).addClass('active');
        $(_parent.con_acd[_value]).css('height', String(_h) + 'px');
        $(_parent.con_acd[_value]).addClass('active');
      } else {
        $(_parent.con_acd[_value]).css('height', '0');
        $(_parent.con_acd[_value]).removeClass('active');
        $(_parent.bt_acd[_value]).removeClass('active');
      }
      return this;
    },
    onResizeHandler: function() {
      var _parent;
      _parent = this;
      return this;
    }
  };


  /*
  
  	タブコンテンツ
   */

  TAB_CNT = {
    bt_tab: [],
    con_tab: [],
    init: function() {
      var _parent;
      _parent = this;
      $('.lnk_tab').each(function() {
        if ($(this).data('id') !== 1) {
          $(this).click(function(event) {
            _parent.onClickHandler($(this));
            return this;
          });
        }
        _parent.bt_tab.push($(this));
        return this;
      });
      this;
      $('.con_tab').each(function() {
        if ($(this).data('id') === 1) {
          $(this).addClass('active');
        }
        _parent.con_tab.push($(this));
        return this;
      });
      return this;
    },
    onClickHandler: function(_target) {
      var _len, _parent, _value, i, k, ref;
      _parent = this;
      _len = _parent.con_tab.length;
      _value = _target.data('id');
      for (i = k = 1, ref = _len; 1 <= ref ? k <= ref : k >= ref; i = 1 <= ref ? ++k : --k) {
        if (i !== _value) {
          _parent.con_tab[i - 1].removeClass('active');
          _parent.bt_tab[i - 1].unbind('click');
          _parent.bt_tab[i - 1].removeClass('active');
          _parent.bt_tab[i - 1].click(function(event) {
            _parent.onClickHandler($(this));
            return this;
          });
        } else {
          _parent.con_tab[i - 1].addClass('active');
          _parent.bt_tab[i - 1].addClass('active');
          _parent.bt_tab[i - 1].unbind('click');
        }
      }
      return this;
    }
  };


  /*
  
  	イメージティッカー
   */

  IMG_TICKER_INIT = {
    obj_tick: {},
    init: function(_target, _direction, _return_value, _arrow, _over_target, _pop) {
      var _parent;
      _parent = this;
      _parent.obj_tick = $.extend(true, {}, makeObject(IMG_TICKER));
      _parent.obj_tick.init(_target, _direction, _return_value, _arrow, _over_target, _pop);
      return this;
    },
    onResizeTick: function() {
      var _parent;
      _parent = this;
      _parent.obj_tick.onResizeTick();
      return this;
    }
  };

  IMG_TICKER = {
    _target: '',
    _target_parent: Object,
    _list: [],
    _contents_width: [],
    _totalImgWidth: 0,
    _direction: '',
    _rendercnt: 0,
    _def_space: 2,
    _space: 0,
    _speed: 33,
    _itv_sl: 0,
    _return_value: 0,
    _bt_left: {},
    _bt_right: {},
    _ret_flg: false,
    _pop: false,
    init: function(_target, _direction, _return_value, _arrow, _over_target, _pop) {
      var _parent, _ul;
      _parent = this;
      _parent._target = _target;
      _parent._direction = _direction;
      _parent._space = _parent._def_space;
      _parent._return_value = _return_value;
      _parent._pop = _pop;
      _ul = $(_target);
      _ul.each(function() {
        _parent._list.push($(this));
        _parent._totalImgWidth += $(_parent._target).width();
        _parent._contents_width.push($(_parent._target).width());
        return this;
      });
      _parent._target_parent = $(_parent._target).parent();
      $(_parent._target_parent).delay(0).animate({
        opacity: 0
      }, 0);
      if (_arrow !== void 0 && _arrow !== '') {
        _parent._bt_left = $(_arrow).find('li.left > a');
        _parent._bt_left.mouseover(function(event) {
          return _parent.onOverBtLeft();
        });
        _parent._bt_right = $(_arrow).find('li.right > a');
        _parent._bt_right.mouseover(function(event) {
          return _parent.onOverBtRight();
        });
      }
      _parent.clonetarget();
      $(_over_target).mouseenter(function(event) {
        return _parent.onOutTraget();
      });
      $(_over_target).mouseleave(function(event) {
        return _parent.onOverTraget();
      });
      return this;
    },
    clonetarget: function() {
      var _img, _parent, _tmp, i, k, len, ref;
      _parent = this;
      len = _parent._list.length * 2;
      for (i = k = 0, ref = len; 0 <= ref ? k <= ref : k >= ref; i = 0 <= ref ? ++k : --k) {
        _tmp = $(_parent._list[i]).clone();
        _img = _tmp.find('a > .img > img');
        _img.attr('alt', '');
        _tmp.addClass('clone');
        _parent._list.push(_tmp);
        $(_parent._target_parent).append(_tmp);
        _parent._totalImgWidth += $(_parent._target).width();
        _parent._contents_width.push($(_parent._target).width());
      }
      $(_parent._target_parent).css("width", _parent._totalImgWidth + "px");
      _parent.startrun();
      return this;
    },
    startrun: function() {
      var _parent;
      _parent = this;
      if (_parent._pop !== void 0 && _parent._pop) {
        $('.image-link').magnificPopup({
          type: 'image',
          removalDelay: 500,
          callbacks: {
            beforeOpen: function() {
              this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
              this.st.mainClass = this.st.el.attr('data-effect');
              return this;
            }
          },
          closeOnContentClick: true,
          midClick: true,
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1],
            tCounter: ''
          },
          image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function(item) {
              return item.el.attr('title');
            }
          }
        });
      }
      $(_parent._target_parent).delay(0).animate({
        opacity: 1
      }, 1200);
      _parent._itv_sl = setInterval($.proxy(_parent.run, _parent), _parent._speed);
      return this;
    },
    run: function(event) {
      var _TL, _TW, _parent, _resetnum, flg_reverse, ref;
      _parent = this;
      flg_reverse = (ref = _parent._direction === "right") != null ? ref : {
        "true": false
      };
      if (!flg_reverse) {
        _TW = parseInt(_parent._list[0].innerWidth());
        _TL = parseInt(_parent._list.length / 3);
        _resetnum = _TW * _TL;
        $(_parent._target_parent).css("margin-left", '-' + String(_parent._rendercnt) + "px");
        _parent._rendercnt += _parent._space;
        if (_parent._rendercnt > _resetnum && !_parent._ret_flg) {
          _parent._ret_flg = true;
          _parent._rendercnt = _parent._rendercnt - _resetnum;
          _parent._ret_flg = false;
        }
      } else {
        _TW = parseInt(_parent._list[0].innerWidth());
        _TL = parseInt(_parent._list.length / 3);
        _resetnum = _TW * _TL;
        $(_parent._target_parent).css("margin-left", '-' + String(_parent._rendercnt) + "px");
        _parent._rendercnt -= _parent._space;
        if (_parent._rendercnt < 500 && !_parent._ret_flg) {
          _parent._ret_flg = true;
          _parent._rendercnt = _parent._rendercnt + _resetnum;
          _parent._ret_flg = false;
        }
      }
      return this;
    },
    restart: function() {
      var _parent;
      _parent = this;
      clearInterval(_parent._itv_sl);
      _parent._itv_sl = setInterval($.proxy(_parent.run, _parent), _parent._speed);
      return this;
    },
    stop: function() {
      var _parent;
      _parent = this;
      clearInterval(_parent._itv_sl);
      return this;
    },
    onOverTraget: function(_target) {
      var _parent;
      _parent = this;
      clearInterval(_parent._itv_sl);
      _parent._itv_sl = setInterval($.proxy(_parent.run, _parent), _parent._speed);
      return this;
    },
    onOutTraget: function(_target) {
      var _parent;
      _parent = this;
      clearInterval(_parent._itv_sl);
      return this;
    },
    onOverBtLeft: function() {
      var _parent;
      _parent = this;
      clearInterval(_parent._itv_sl);
      _parent._direction = 'right';
      _parent._itv_sl = setInterval($.proxy(_parent.run, _parent), 9);
      _parent._bt_left.mouseout(function(event) {
        return _parent.onOutBtLeft();
      });
      return this;
    },
    onOutBtLeft: function() {
      var _parent;
      _parent = this;
      _parent._bt_left.unbind('mouseout');
      _parent._direction = 'left';
      clearInterval(_parent._itv_sl);
      _parent._itv_sl = setInterval($.proxy(_parent.run, _parent), _parent._speed);
      return this;
    },
    onOverBtRight: function() {
      var _parent;
      _parent = this;
      clearInterval(_parent._itv_sl);
      _parent._direction = 'left';
      _parent._itv_sl = setInterval($.proxy(_parent.run, _parent), 9);
      _parent._bt_right.mouseout(function(event) {
        return _parent.onOutBtRight();
      });
      return this;
    },
    onOutBtRight: function() {
      var _parent;
      _parent = this;
      _parent._bt_right.unbind('mouseout');
      clearInterval(_parent._itv_sl);
      _parent._itv_sl = setInterval($.proxy(_parent.run, _parent), _parent._speed);
      return this;
    },
    onResizeTick: function() {
      var _img, _parent, _tmp, i, k, len, ref;
      _parent = this;
      len = _parent._list.length;
      _parent._contents_width = [];
      _parent._totalImgWidth = 0;
      for (i = k = 0, ref = len; 0 <= ref ? k <= ref : k >= ref; i = 0 <= ref ? ++k : --k) {
        _tmp = $(_parent._list[i]);
        _img = _tmp.find('a > .img > img');
        _parent._totalImgWidth += $(_parent._target).width();
        _parent._contents_width.push($(_parent._target).width());
      }
      $(_parent._target_parent).css("width", _parent._totalImgWidth + "px");
      return this;
    }
  };


  /*
  
  	季節を取得
   */

  GET_SEASON = {
    _spr_f: '',
    _spr_t: '',
    _sum_f: '',
    _sum_t: '',
    _aut_f: '',
    _aut_t: '',
    _win_f1: '',
    _win_t1: '',
    _win_f2: '',
    _win_t2: '',
    init: function(spr_f, spr_t, sum_f, sum_t, aut_f, aut_t, win_f1, win_t1, win_f2, win_t2) {
      var _m, _parent, _y;
      if (spr_f == null) {
        spr_f = '3/1';
      }
      if (spr_t == null) {
        spr_t = '5/31';
      }
      if (sum_f == null) {
        sum_f = '6/1';
      }
      if (sum_t == null) {
        sum_t = '8/31';
      }
      if (aut_f == null) {
        aut_f = '9/1';
      }
      if (aut_t == null) {
        aut_t = '10/30';
      }
      if (win_f1 == null) {
        win_f1 = '11/1';
      }
      if (win_t1 == null) {
        win_t1 = '12/31';
      }
      if (win_f2 == null) {
        win_f2 = '1/1';
      }
      if (win_t2 == null) {
        win_t2 = '2/28';
      }
      _parent = this;
      _parent._spr_f = spr_f;
      _parent._spr_t = spr_t;
      _parent._sum_f = sum_f;
      _parent._sum_t = sum_t;
      _parent._aut_f = aut_f;
      _parent._aut_t = aut_t;
      _parent._win_f1 = win_f1;
      _parent._win_t1 = win_t1;
      _parent._win_f2 = win_f2;
      _parent._win_t2 = win_t2;
      _m = new Date();
      _y = _m.getFullYear();
      return _parent.getseason(_m, _y);
    },
    getseason: function(_date, _year) {
      var _parent;
      _parent = this;
      return _parent.calcseason(_date, _year);
    },
    calcseason: function(_date, _year) {
      var _aut1, _aut2, _n, _parent, _season, _spr1, _spr2, _sum1, _sum2, _win1, _win2, _win3, _win4, _x;
      _parent = this;
      _n = 0;
      _x = 0;
      _spr1 = 0;
      _spr2 = 0;
      _sum1 = 0;
      _sum2 = 0;
      _aut1 = 0;
      _aut2 = 0;
      _win1 = 0;
      _win2 = 0;
      _win3 = 0;
      _win4 = 0;
      _season = '';
      _n = Date.parse(_year + "/" + _parent._spr_f);
      _x = (_date - _n) / 1000 / 60 / 60 / 24;
      _spr1 = Math.floor(_x);
      _n = Date.parse(_year + "/" + _parent._spr_t);
      _x = (_date - _n) / 1000 / 60 / 60 / 24;
      _spr2 = Math.floor(_x);
      _n = Date.parse(_year + "/" + _parent._sum_f);
      _x = (_date - _n) / 1000 / 60 / 60 / 24;
      _sum1 = Math.floor(_x);
      _n = Date.parse(_year + "/" + _parent._sum_t);
      _x = (_date - _n) / 1000 / 60 / 60 / 24;
      _sum2 = Math.floor(_x);
      _n = Date.parse(_year + "/" + _parent._aut_f);
      _x = (_date - _n) / 1000 / 60 / 60 / 24;
      _aut1 = Math.floor(_x);
      _n = Date.parse(_year + "/" + _parent._aut_t);
      _x = (_date - _n) / 1000 / 60 / 60 / 24;
      _aut2 = Math.floor(_x);
      _n = Date.parse(_year + "/" + _parent._win_f1);
      _x = (_date - _n) / 1000 / 60 / 60 / 24;
      _win1 = Math.floor(_x);
      _n = Date.parse(_year + "/" + _parent._win_t1);
      _x = (_date - _n) / 1000 / 60 / 60 / 24;
      _win2 = Math.floor(_x);
      _n = Date.parse(_year + "/" + _parent._win_f2);
      _x = (_date - _n) / 1000 / 60 / 60 / 24;
      _win3 = Math.floor(_x);
      _n = Date.parse(_year + "/" + _parent._win_t2);
      _x = (_date - _n) / 1000 / 60 / 60 / 24;
      _win4 = Math.floor(_x);
      if ((_spr1 >= 0) && (_spr2 <= 0)) {
        _season = 'spring';
      } else if ((_sum1 >= 0) && (_sum2 <= 0)) {
        _season = 'summer';
      } else if ((_aut1 >= 0) && (_aut2 <= 0)) {
        _season = 'autmun';
      } else if ((_win1 >= 0) && (_win2 <= 0)) {
        _season = 'winter';
      } else if ((_win3 >= 0) && (_win4 <= 0)) {
        _season = 'winter';
      } else {
        _season = 'noseason';
      }
      return _season;
    }
  };


  /*
  
  	ランダムな数を返す
   */

  GET_RANDOMNUM = {
    init: function(_val) {
      var a, i, j, tmp;
      i = new Array(_val);
      j = new Array(_val);
      tmp = new Array(_val);
      a = new Array(_val);
      return function() {
        if (_val > 0) {
          i = _val - 1;
          j = Math.floor(Math.random() * _val);
          tmp = a[i] || i;
          a[i] = a[j] || j;
          a[j] = tmp;
          _val = i;
          return a[i];
        } else {
          return null;
        }
      };
    }
  };


  /*
  
  	季節用処理
   */

  SEASON_EFFECT = {
    SEASON_PERTICLE: function() {},
    MAX_PATICLE: 20,
    uri: '',
    ss: '',
    itv: 0,
    target: {},
    init: function(_ss, _target) {
      var _prt;
      _prt = this;
      _prt.uri = _uri_h;
      _prt.ss = _ss;
      _prt.target = _target;
      _prt.setObjct();
      return this;
    },
    start_pt: function() {
      var _dur, _prt, _sf, _th, _tp, _tw;
      _prt = this;
      _sf = new _prt.SEASON_PERTICLE;
      _tw = _prt.target.innerWidth();
      _th = _prt.target.innerHeight();
      _tp = _prt.target.scrollTop();
      _dur = 0;
      if (_prt.ss === 'summer' || _prt.ss === 'spring') {
        _dur = 800;
        _prt.MAX_PATICLE = 30;
      } else if (_prt.ss === 'winter') {
        _dur = 500;
        _prt.MAX_PATICLE = 50;
      } else {
        _dur = 1500;
      }
      _prt.itv = setInterval(function() {
        _sf.emit(_tw, _th, _tp);
        return this;
      }, _dur);
      return this;
    },
    setObjct: function() {
      var _pick, _prt, _random, _rm;
      _prt = this;
      _random = function(t, e) {
        return Math.floor(Math.random() * (e - t + 1)) + t;
      };
      _rm = function(t, e) {
        var ref;
        return (ref = _random(0, 1)) != null ? ref : {
          t: e
        };
      };
      _pick = function() {
        var _val;
        _val = 0;
        if (arguments[0] instanceof Array) {
          _val = _random(0, arguments[0].length - 1);
          return arguments[0][_val];
        } else {
          _val = _random(0, arguments.length - 1);
          return arguments[_val];
        }
      };
      _prt.SEASON_PERTICLE.prototype = {
        create: function(_uri, _w, _h, _z) {
          return $(document.createElement("img")).addClass("particle").attr("src", _uri).css({
            height: _h,
            width: _w,
            zIndex: _z
          }).appendTo(_prt.target);
        },
        emit: function(_tw, _th, _tp) {
          var _img, _mo, _prop, _r, _uri;
          _r = 0;
          if (_prt.ss === "summer") {
            _r = _th * .5;
          } else {
            _r = _th * 1.5;
          }
          if (_tp >= _r) {
            return;
          }
          if ($(".particle").length > _prt.MAX_PATICLE) {
            return;
          }
          _prop = this.getProp();
          _uri = _prt.uri + _prop.uri;
          _img = this.create(_uri, _prop.w, "auto", _prop.z);
          _mo = null;
          if (_prt.ss === "summer") {
            _mo = this.getMotion4(_tw, _th, _tp, _r, _prop);
          } else if (_prt.ss === "winter") {
            _mo = this.getMotion3(_tw, _th, _tp, _r, _prop);
          } else {
            _mo = this.getMotion1(_tw, _th, _tp, _r, _prop);
          }
          $.Velocity.animate(_img.get(0), _mo, {
            begin: function(t) {
              setTimeout(function() {
                $(t[0]).show();
                return this;
              }, 100);
              return this;
            },
            complete: function(t) {
              var e;
              e = t[0];
              $(e).remove();
              return this;
            },
            duration: _prop.duration,
            progress: function(t, e, n, i, r) {
              var a, hide;
              a = t[0];
              hide = 0;
              if (_prt.ss === 'summer') {
                hide = .9;
              } else {
                hide = .6;
              }
              if (e > hide) {
                if (!a.hasAttribute("data-finish")) {
                  a.setAttribute("data-finish", "1");
                  $(a).removeClass("blink").css({
                    opacity: 0
                  });
                }
              }
              return this;
            }
          });
          return this;
        },
        getMotion1: function(t, e, n, i, a) {
          var c, l, s, u;
          s = n + a.h * -1;
          u = _random(0, t + t / 2);
          l = n + i - s;
          c = u - t / 2.5;
          return {
            rotateX: _random(0, 80),
            rotateY: _random(0, 80),
            rotateZ: _random(-80, 80),
            translateX: [c, _pick("easeInCubic", "easeInOutQuad"), u],
            translateY: [l, "linear", s]
          };
        },
        getMotion2: function(t, e, n, i, a) {
          var c, l, s, u;
          s = e + n + a.h;
          u = _random(0, t);
          l = n + _random(-20, 20);
          c = u + _random(-t / 2, t / 2);
          return {
            rotateX: _random(0, 50),
            rotateY: _random(0, 50),
            rotateZ: _random(-50, 50),
            translateX: [c, _pick("easeInOutBounce", "easeOutBounce", "easeInOutElastic", [0, 1.25, 1, -.29]), u],
            translateY: [l, _pick("linear", "easeOutQuad", "easeOutQuint", [.39, 1, .8, .01]), s]
          };
        },
        getMotion3: function(t, e, n, i, a) {
          var l, o, s, u;
          o = n + a.h * -1;
          s = _random(0, t + t / 2);
          u = n + i - o;
          l = s + _random(10, 10);
          return {
            rotateX: _random(0, 36),
            rotateY: _random(0, 36),
            rotateZ: _random(-36, 36),
            translateX: [l, "easeInCubic", s],
            translateY: [u, "linear", o]
          };
        },
        getMotion4: function(t, e, n, i, a) {
          var c, l, s, u;
          s = n + a.h * -1;
          u = _random(0, t + t / 2);
          l = n + i - s;
          c = u - t / 2.5;
          return {
            rotateX: _random(0, 20),
            rotateY: _random(0, 20),
            rotateZ: _random(-20, 20),
            translateX: [c, _pick("easeInCubic", "easeInOutQuad"), u],
            translateY: [l, "linear", s]
          };
        },
        getProp: function() {
          var _prop;
          _prop = [];
          switch (_prt.ss) {
            case 'spring':
              _prop = [
                {
                  duration: 14000,
                  h: 31,
                  uri: "spr_01.png",
                  w: 31,
                  z: 100
                }, {
                  duration: 16000,
                  h: 31,
                  uri: "spr_02.png",
                  w: 31,
                  z: 100
                }, {
                  h: 25,
                  duration: 18000,
                  uri: "spr_03.png",
                  w: 37,
                  z: 100
                }, {
                  duration: 20000,
                  h: 29,
                  uri: "spr_04.png",
                  w: 23,
                  z: 100
                }
              ];
              break;
            case 'summer':
              _prop = [
                {
                  duration: 6000,
                  h: 52,
                  uri: "sum_01.png",
                  w: 31,
                  z: 100
                }, {
                  duration: 8000,
                  h: 41,
                  uri: "sum_02.png",
                  w: 22,
                  z: 100
                }, {
                  h: 20,
                  duration: 10000,
                  uri: "sum_03.png",
                  w: 38,
                  z: 100
                }, {
                  duration: 12000,
                  h: 38,
                  uri: "sum_04.png",
                  w: 18,
                  z: 100
                }
              ];
              break;
            case 'autmun':
              _prop = [
                {
                  duration: 14000,
                  h: 51,
                  uri: "aut_01.png",
                  w: 64,
                  z: 100
                }, {
                  duration: 16000,
                  h: 51,
                  uri: "aut_02.png",
                  w: 64,
                  z: 100
                }, {
                  h: 49,
                  duration: 18000,
                  uri: "aut_03.png",
                  w: 62,
                  z: 100
                }, {
                  duration: 20000,
                  h: 52,
                  uri: "aut_04.png",
                  w: 62,
                  z: 100
                }
              ];
              break;
            case 'winter':
              _prop = [
                {
                  duration: 20000,
                  h: 38,
                  uri: "win_01.png",
                  w: 38,
                  z: 100
                }, {
                  duration: 22000,
                  h: 24,
                  uri: "win_02.png",
                  w: 24,
                  z: 100
                }, {
                  h: 20,
                  duration: 24000,
                  uri: "win_03.png",
                  w: 20,
                  z: 100
                }, {
                  duration: 26000,
                  h: 47,
                  uri: "win_04.png",
                  w: 47,
                  z: 100
                }
              ];
              break;
            default:
              break;
          }
          return _pick(_prop);
        }
      };
      _prt.start_pt();
      return this;
    }
  };


  /*
  
  	ページ共通処理
   */

  SAYANOYUDOKORO_MAIN = {
    init: function() {
      var _parent;
      _parent = this;
      return this;
    }
  };


  /*
  
  	オブジェクトをインスタンス化
   */

  settings_main = makeObject(SETTINGS);


  /*
  
  	@for debug
   */

  trace = function(log) {
    settings_main.debug.DUBUG_MODE = true;
    settings_main.debug.trace(log);
    return this;
  };


  /*
  	@initializer
   */

  initmain = function() {

    /*
    		横幅から環境を判断する
     */
    modeChange();

    /*
    
    	アコーディオンコンテンツ
     */
    $doc.obj_acd = makeObject(ACD_CNT);

    /*
    
    	タブコンテンツ
     */
    $doc.obj_tab = makeObject(TAB_CNT);

    /*
    
    	イメージティッカー
     */
    $doc.obj_tick = makeObject(IMG_TICKER_INIT);

    /*
    
    	季節を取得
     */
    $doc.obj_season = makeObject(GET_SEASON);

    /*
    
    	乱数
     */
    $doc.obj_radm = makeObject(GET_RANDOMNUM);
    if ($('#homepage').length !== 0) {
      $doc.obj_ssf = makeObject(SEASON_EFFECT);
    }
    return this;
  };

  startSteing = function() {
    return this;
  };


  /*
  	ページ処理関数各種
   */

  getWindouSize = function() {
    obj_settings._windowW = parseInt($(window).width());
    obj_settings._windowH = parseInt($(window).height());
    return this;
  };

  resizeCommon = function() {

    /*
    		横幅から環境を判断する
     */
    modeChange();
    return this;
  };

  changeTextSize = function() {
    return this;
  };

  settingPC = function() {
    return this;
  };

  settingTAB = function() {
    return this;
  };

  settingSP = function() {
    return this;
  };

  scrollEvt = function(_valueY) {
    return this;
  };

  moveMousePotion = function(_valueY, _valueX) {
    return this;
  };


  /*
  
  	現在のウィンドウサイズを調べるてフラグをつける
   */

  modeChange = function() {
    var _w;
    _w = parseInt($(window).width());
    if (_w >= $doc.bp_pc_com) {
      $doc.pc_flag = true;
      $doc.tab_flag = false;
      $doc.tab_s_flag = false;
      $doc.sp_flag = false;
    } else if (_w <= $doc.bp_pc_com && _w > $doc.bp_tab_s_com) {
      $doc.pc_flag = false;
      $doc.tab_flag = true;
      $doc.tab_s_flag = false;
      $doc.sp_flag = false;
    } else if (_w <= $doc.bp_tab_s_com && _w > $doc.bp_tab_com) {
      $doc.pc_flag = false;
      $doc.tab_flag = false;
      $doc.tab_s_flag = true;
      $doc.sp_flag = false;
    } else {
      $doc.pc_flag = false;
      $doc.tab_flag = false;
      $doc.tab_s_flag = false;
      $doc.sp_flag = true;
    }
    return this;
  };


  /*
  
  	ドキュメント・ウィンドウ各種イベント
   */


  /*
  	redyEVENT
   */

  $(document).ready(function() {
    obj_settings = makeObject(SAYANOYUDOKORO_MAIN).init();
    initmain();
    return this;
  });


  /*
  	loadEVENT
   */

  $(window).load(function() {
    getWindouSize();
    startSteing();
    return this;
  });


  /*
  	resizeEVENT & rotateEVENT
   */

  $(window).resize(function() {
    var _h, _w;
    _w = parseInt($(window).width());
    _h = parseInt($(window).height());
    if (_w !== obj_settings._windowW || _h !== obj_settings._windowH) {
      getWindouSize();
      resizeCommon();
    }
    changeTextSize();
    return this;
  });


  /*
  	Scroll
   */

  $(window).scroll(function() {
    scroll_y = $(this).scrollTop();
    scrollEvt(scroll_y);
    return this;
  });


  /*
  
  	Array extends
   */

  Array.prototype.end = function() {
    return this[this.length(-1)];
  };

  Array.prototype.shuffle = function() {
    var i, n, t;
    n = this.length;
    while (n) {
      i = Math.floor(Math.random() * n--);
      t = this[n];
      this[n] = this[i];
      this[i] = t;
    }
    return this;
  };

  Array.prototype.chunk = function(n) {
    var i, len1, result;
    len1 = Math.round(this.length / n, 10);
    result = [];
    i = 0;
    while (i < len1) {
      result.push(this.slice(i * n, i * n + n));
      i++;
    }
    return result;
  };

}).call(this);
